import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';

import css from './ListingPage.module.css';
import SectionAvatar from './SectionAvatar';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    category,
    hostLink,
    onContactUser,
    listing,
    isOwnListing,
    currentAuthor,
    params
  } = props;

  const availabilityPlanType = listing?.attributes?.publicData?.availability || 'daily';
  const unitTranslationKey =
    availabilityPlanType === 'daily'
      ? 'ManageListingCard.perDay'
      : 'ManageListingCard.perHour';

  const showContactUser = !isOwnListing;

  return (
    <div className={css.sectionHeading}>
      <div className={css.desktopPriceContainer}>

        <div className={css.desktopPriceValue} title={priceTitle}>
          {formattedPrice}
          <div className={css.desktopPerUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

      </div>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {category}
          <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
          {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
